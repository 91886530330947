
//componenents
import NavTab from '../NavTab/NavTab';

function Promo() {


  return (
    <section className="promo">
      <h1 className="promo__heading">Учебный проект студента факультета Веб-разработки.</h1>
      <NavTab />
    </section>
  )
}

export default Promo;
